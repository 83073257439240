import React from 'react';
import { Typography, Link } from '@material-ui/core';
import constants from '../../constants';

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '} {new Date().getFullYear()} {' '}
            <Link color="inherit" href="/">
                {constants.appName}
            </Link>
            .
      </Typography>
    );
}

export default Copyright;