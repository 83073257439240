import { Button, Grid, Paper } from "@material-ui/core";
import axios from "axios";
import { onMessage } from "firebase/messaging";
import React from "react";
import police from "../../assets/sound/siren.mp3";
import constants from "../../constants";
import { useAuth } from "../../context/auth";
import { MapMonitorContext } from "../../context/mapMonitor";
import { useSnackBar } from "../../context/snackBar";
import { messaging } from "../../firebase";
import { useWithSound } from "../../useWithSound";
import Layout from "../common/Layout";
import CardContainer from "../monitor/CardContainer";
import Detail from "../monitor/Detail";
import Map from "../monitor/Map";

const height = "85";

const MapaDenunciasView = () => {
  const { auth } = useAuth();
  const [positions, setPositions] = React.useState([]);
  const [points, setPoints] = React.useState([]);
  const [serviceZones, setServiceZones] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [prevZoom, setPrevZoom] = React.useState(null);
  const [map, setMap] = React.useState(null);
  const [mapApi, setMapApi] = React.useState(null);
  const [prevCoordinates, setPrevCoordinates] = React.useState(null);
  const [showSectors, setShowSectors] = React.useState(true);
  const { openSnackBar } = useSnackBar();
  const { playSound } = useWithSound(police);
  
  const headers = {
    "Content-Type": "application/json",
    Authorization: auth.token,
  };

  const handleSetSelectedItem = (value) => {
    setSelectedItem(value);
  };

  const handleSetPrevZoom = (zoom) => {
    setPrevZoom(zoom);
  };

  const handleSetMap = (map) => {
    setMap(map);
  };

  const handleSetMapApi = (mapApi) => {
    setMapApi(mapApi);
  };

  const handleSetPrevCoordinates = (prevCoordinates) => {
    setPrevCoordinates(prevCoordinates);
  };

  function requestAll() {
    return axios
      .get(constants.apiBase + "web/reports", {
        headers,
      })
      .then((result) => {
        return result.data.data;
      })
      .catch((e) => {
        return [];
      });
  }

  function requestAgentsPositions() {
    return axios
      .get(constants.apiBase + "web/positions/agents", {
        headers,
      })
      .then((result) => {
        return result.data.data;
      })
      .catch((e) => {
        return [];
      });
  }

  function requestPuntosService() {
    return axios
      .get(constants.apiBase + "web/points", { headers })
      .then((result) => {
        return result.data.data;
      })
      .catch((e) => {
        console.error(e);
        return null;
      });
  }

  function requestAll() {
    return axios
      .get(constants.apiBase + "web/reports", {
        headers,
      })
      .then((result) => {
        return result.data.data;
      })
      .catch((e) => {
        return [];
      });
  }

  function requestZoneServices() {
    return axios
      .get(constants.apiBase + "web/zone-service", {
        headers,
      })
      .then((result) => {
        return result.data.data;
      })
      .catch((e) => {
        return [];
      });
  }

  const handeShowSectorsAction = () => {
    playSound();
    setShowSectors(!showSectors);
  };

  React.useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // ...
      playSound();
      openSnackBar({
        open: true,
        text: payload.data !== undefined ? payload.data.body : payload.notification.title,
      });
    });

    requestAll().then((result) => {
      setData(result);
    });

    requestAgentsPositions().then((result) => {
      setPositions(result);
    });

    requestZoneServices().then((result) => {
      setServiceZones(result);
    });

    requestPuntosService().then((result) => {
      setPoints(result);
    });

    const interval = setInterval(() => {
      requestAll().then((result) => {
        setData(result);
      });
    }, 35000);

    const intervalPositions = setInterval(() => {
      requestAgentsPositions().then((result) => {
        setPositions(result);
      });
    }, 80000);

    const intervalPoints = setInterval(() => {
      requestPuntosService().then((result) => {
        setPositions(result);
      });
    }, 80000);

    return () => {
      clearInterval(interval);
      clearInterval(intervalPositions);
      clearInterval(intervalPoints);
    };
  }, []);

  return (
    <React.Fragment>
      <Layout headerTitle="Mapa de Monitoreo">
        {[1, 2, 3].includes(auth.account.admin.role.id) ? (
          <MapMonitorContext.Provider
            value={{
              selectedItem,
              setSelectedItem: handleSetSelectedItem,
              map: map,
              mapApi: mapApi,
              setMap: handleSetMap,
              setMapApi: handleSetMapApi,
              prevZoom: prevZoom,
              setPrevZoom: handleSetPrevZoom,
              prevCoordinates: prevCoordinates,
              setPrevCoordinates: handleSetPrevCoordinates,
              serviceZones: serviceZones,
              showSectors: showSectors,
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} lg={8} xl={9}>
                <Paper>
                  <Map
                    data={data}
                    positions={positions}
                    points={points}
                    height={height}
                    showSectors={showSectors}
                  />
                </Paper>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "1em" }}
                  onClick={handeShowSectorsAction}
                >
                  {showSectors ? "Ocultar Sectores" : "Mostrar Sectores"}
                </Button>
              </Grid>
              <Grid item xs={12} lg={4} xl={3}>
                <Paper>
                  <CardContainer data={data} height={height} />
                  {selectedItem != null ? <Detail height={height} /> : ""}
                </Paper>
              </Grid>
            </Grid>
          </MapMonitorContext.Provider>
        ) : (
          "No tiene los privilegios requeridos"
        )}
      </Layout>
    </React.Fragment>
  );
};

export default MapaDenunciasView;
