import { Button, Grid, makeStyles, Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import SectorDataTable from 'components/sector/SectorDataTable';
import SectorFormDialog from 'components/sector/SectorFormDialog';
import React, { useState } from 'react';
import constants from '../../constants';
import { useAuth } from "../../context/auth";
import { useSnackBar } from "../../context/snackBar";
import Layout from '../common/Layout';

const useStyles = makeStyles(theme => ({
    tableContainer: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }
}));

const SectorView = React.memo(() => {
    const { auth } = useAuth();
    const [data, setData] = useState([]);
    const { openSnackBar } = useSnackBar();
    const classes = useStyles();
    let [selectedItem, setSelectedItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState('create');
    const [viewPoints, setViewPoints] = useState(false);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': auth.token
    }

    function requestSectorsService() {
        return axios.get(constants.apiBase + "web/sectors", { headers }).then(result => {
            return result.data.data;
        }).catch(e => {
            console.error(e);
            return null;
        });
    }

    function deleteSectorService(id) {
        return axios.delete(constants.apiBase + "web/sectors/" + id, { headers }).then(result => {
            openSnackBar({ open: true, variant: 'success', text: 'Eliminado correctamente' })
            return Promise.resolve();
        }).catch(e => {
            console.error(e);
            return null;
        });
    }

    function requestSaveSector(sector) {
        const _headers = Object.assign({}, headers);
        const payload = { ...sector, serviceZoneId: 1 };

        return axios.post(constants.apiBase + "web/sectors", payload, { headers: _headers }).then(result => {
            openSnackBar({ open: true, variant: 'success', text: 'Se proceso lo solicitado correctamente' });
            setDialogOpen(false);
            requestSectors();
        }).catch(e => {
            openSnackBar({ open: true, variant: 'error', text: 'No se puedo procesar lo solicitado correctamente' })
            console.error(e);
            return null;
        });
    }

    const handeCreateAction = (e) => {
        setSelectedItem({});
        setViewPoints(false);
        setDialogAction("create");
        setDialogOpen(true);
    }

    const handleClose = () => {
        setDialogOpen(false);
        setViewPoints(false);
    }

    function requestUpdateSector(sector) {
        const _headers = Object.assign({}, headers);
        const payload = { ...sector, serviceZoneId: 1 };

        return axios.put(constants.apiBase + "web/sectors/" + sector.id, payload, { headers: _headers }).then(result => {
            openSnackBar({ open: true, variant: 'success', text: 'Se proceso lo solicitado correctamente' });
            setDialogOpen(false);
            requestSectors();
        }).catch(e => {
            openSnackBar({ open: true, variant: 'error', text: 'No se puedo procesar lo solicitado correctamente' })
            console.error(e);
            return null;
        });
    }

    const handeUpdateAction = (item) => {
        setSelectedItem(item);
        setDialogAction("update");
        setDialogOpen(true);
    }

    const handeDeleteAction = (id) => {
        deleteSectorService(id).then(() => requestSectors());
    }

    const requestSectors = () => {
        requestSectorsService().then((result) => setData(result));
    }

    const handleClickViewPoints = points => {
        setSelectedItem({ points });
        setViewPoints(true);
        setDialogOpen(true);
        setDialogAction("viewPoints");
    }

    React.useEffect(() => {
        requestSectors();
    }, []);

    return (
        <React.Fragment>
            <Layout headerTitle="Sector">
                <Grid item xs={12}>
                    <Button onClick={handeCreateAction} variant="contained" color="primary" style={{ marginBottom: "1rem", padding: ".3rem .6rem", fontSize: "12px" }}>
                        <AddIcon /> Crear nuevo
                    </Button>
                    <Paper className={classes.tableContainer}>
                        <SectorDataTable data={data} clickRow={handeUpdateAction} clickViewPoints={handleClickViewPoints} deleteRow={handeDeleteAction} />
                    </Paper>
                </Grid>
                <SectorFormDialog open={dialogOpen} action={dialogAction} handleClose={handleClose} data={selectedItem}
                    updateHandler={requestUpdateSector} saveHandler={requestSaveSector} viewPoints={viewPoints}/>
            </Layout>
        </React.Fragment>
    );
});

export default SectorView;
