import { Button, Grid, Paper } from '@material-ui/core';
import axios from 'axios';
import React from 'react';
import constants from '../../constants';
import { useAuth } from "../../context/auth";
import { MapMonitorContext } from "../../context/mapMonitor";
import Layout from '../common/Layout';
import Filtros from '../heatMap/Filtros';
import HeatMap from '../heatMap/HeatMap';
import CardContainer from '../monitor/CardContainer';
import Detail from '../monitor/Detail';


const height = "75";

const MapaDenunciasView = () => {
    const [data, setData] = React.useState([]);
    const [heatData, setHeatData] = React.useState([]);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [serviceZones, setServiceZones] = React.useState(null);
    const [showSectors, setShowSectors] = React.useState(true);
    const { auth } = useAuth();

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': auth.token
    }

    const handleSetSelectedItem = (value) => {
        setSelectedItem(value)
    }

    function handleSetData(data) {
        setData(data);
    }

    function handleSetHeatData(data) {
        setHeatData(data);
    }

    function requestZoneServices() {
        return axios.get(constants.apiBase + "web/zone-service", {
            headers
        }).then(result => {
            return result.data.data;
        }).catch(e => {
            return [];
        });
    }

    const handeShowSectorsAction = () => {
        setShowSectors(!showSectors);
    }

    React.useEffect(() => {
        requestZoneServices().then(result => {
            setServiceZones(result);
        })
      }, []);
    

    return (
        <React.Fragment>
            <Layout headerTitle="Mapa Historico">
                {[1, 2, 3].includes(auth.account.admin.role.id) ?
                    <MapMonitorContext.Provider value={{
                        selectedItem, setSelectedItem: handleSetSelectedItem,
                        serviceZones, showSectors
                    }}>
                        <Grid container spacing={3}>
                            <Filtros setData={handleSetData} setHeatData={handleSetHeatData} />
                            <Grid item xs={12} lg={8} xl={9} > 
                                <Paper>
                                    <HeatMap data={heatData} height={height} />
                                </Paper>
                                <Button variant="contained" color="primary" style={{ marginTop: '1em' }}
                                    onClick={handeShowSectorsAction}>
                                    {showSectors ? 'Ocultar Sectores' : 'Mostrar Sectores'}
                                </Button>
                            </Grid>
                            <Grid item xs={12} lg={4} xl={3}>
                                <Paper>
                                    <CardContainer data={data} height={height} />
                                    {selectedItem != null ? <Detail height={height} /> : ''}
                                </Paper>
                            </Grid>
                        </Grid>
                    </MapMonitorContext.Provider>
                    : 'No tiene los privilegios requeridos'}
            </Layout>
        </React.Fragment>
    );
};

export default MapaDenunciasView;
