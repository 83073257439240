import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import Layout from '../common/Layout';
import { useAuth } from "../../context/auth";
import constants from '../../constants';

const useStyles = makeStyles(theme => ({
  fixedHeight: {
    height: '85vh',
  },
  image: {
    marginBottom: '-85vh !important',
    backgroundImage: 'url(' + constants.urlBase + 'storage/images/login/dashboard.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
}));

const DashboardView = () => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const { auth } = useAuth();

  return (
    <React.Fragment>
      <Layout>
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.image}>
            <Paper>
              
            </Paper>
          </Grid>
        </Grid>
      </Layout>
    </React.Fragment>
  );
}

export default DashboardView;