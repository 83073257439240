import GoogleMapReact from 'google-map-react';
import React from 'react';
import constants from '../../constants';
import { useMapMonitor } from "../../context/mapMonitor";

let gMap = null;
let mapsApi = null;
let heatMap = null;

let serviceZonePolygons = [];
let sectorLabelMarkers = [];

const OPTIONS = {
    minZoom: 12,
    maxZoom: 18,
    streetViewControl: true
}

const Map = React.memo((props) => {
    const { selectedItem, serviceZones, showSectors } = useMapMonitor();

    function prepareHeatLayer() {
        let heatmapData = [];
        props.data.forEach(element => {
            heatmapData.push({ 'location': new mapsApi.LatLng(element.location.lat, element.location.lng), 'weight': element.weight })
        });
        const _heatmap = new mapsApi.visualization.HeatmapLayer({
            data: heatmapData
        });
        if (heatMap != null) {
            heatMap.setMap(null);
        }
        _heatmap.setMap(gMap);
        heatMap = _heatmap;
    }

    const getSectorsRandomColors = (sectorPoints) => {
        let randomColors = [];
        let duplicates = true;
        while (duplicates) {
            randomColors = sectorPoints.map((_, index) => constants.colors.length > index ? constants.colors[index] : constants.randColor());
            duplicates = randomColors.some((color, index) => randomColors.indexOf(color) !== index);
        }
    
        return randomColors;
    }

    function handleSelectedItem() {
        if (selectedItem == null || selectedItem.item == null || gMap == null || mapsApi == null) {
            return;
        }

        gMap.setCenter({ lat: parseFloat(selectedItem.item.latitude), lng: parseFloat(selectedItem.item.longitude) });
        if (gMap.getZoom() < 18) {
            mapsApi.event.addListenerOnce(gMap, 'idle', function () {
                gMap.setZoom(18);
            });
        }
    }

    const handleServiceZones = () => {
        if (!gMap || !mapsApi) {
            return;
        }

        if (!showSectors) {
            serviceZonePolygons.forEach(sectorPolygon => sectorPolygon.setMap(null));
            serviceZonePolygons = [];
            sectorLabelMarkers.forEach(sectorLabelMarker => sectorLabelMarker.setMap(null));
            sectorLabelMarkers = [];
            return;
        }

        const fatherSectorPoints = JSON.parse(serviceZones.points)
            .map(({ lat, lng }) => ({ lat: Number(lat), lng: Number(lng) }));

        const fatherSectorPolygon = new mapsApi.Polygon({
            paths: fatherSectorPoints,
            strokeColor: "#FF0000",
            strokeOpacity: 1,
            strokeWeight: 1.9,
            fillColor: "#FF0000",
            fillOpacity: 0
        })


        fatherSectorPolygon.setMap(gMap);
        serviceZonePolygons.push(fatherSectorPolygon);

        const sectorPoints = serviceZones.sectors.map(sector => ({ ...sector, points: JSON.parse(sector.points) }))
            .map(sector => ({ ...sector, points: sector.points.map(({ lat, lng }) => ({ lat: Number(lat), lng: Number(lng) })) }))
            .sort(constants.compare);

        const randomColors = getSectorsRandomColors(sectorPoints);

        const invisibleIcon = new mapsApi.MarkerImage(
            "/invisible.PNG",
            null, /* size is determined at runtime */
            null, /* origin is 0,0 */
            null, /* anchor is bottom center of the scaled image */
            new mapsApi.Size(32, 32)
        );

        sectorPoints.forEach((sector, index) => {
            const color = randomColors[index];
            const sectorPolygon = new mapsApi.Polygon({
                paths: sector.points,
                strokeColor: color,
                strokeOpacity: 0.9,
                strokeWeight: 0.6,
                fillColor: color,
                fillOpacity: 0.09
            });

            sectorPolygon.setMap(gMap);
            serviceZonePolygons.push(sectorPolygon);

            const bounds = new mapsApi.LatLngBounds();
            sector.points.forEach(point => bounds.extend(point));
            const polygonCenter = bounds.getCenter();

            const labelMarker = new mapsApi.Marker({
                position: polygonCenter,
                label: {
                    text: sector.name,
                    color: '#192841',
                    fontSize: '22px',
                    fontWeight: "bold"
                },
                gMap,
                icon: invisibleIcon
            });

            labelMarker.setMap(gMap);
            sectorLabelMarkers.push(labelMarker);
        });

    }


    React.useEffect(() => {
        if (mapsApi != null && gMap != null) {
            prepareHeatLayer();
        }
    }, [gMap, mapsApi, props.data])

    React.useEffect(() => {
        handleSelectedItem();
    }, [selectedItem])

    React.useEffect(() => {
        handleServiceZones();
    }, [serviceZones, showSectors]);

    return (
        <div id="mapContainer" style={{ height: props.height + 'vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: constants.googleMapsApi }}
                defaultCenter={constants.defaultMapsCenter}
                defaultZoom={15.7}
                yesIWantToUseGoogleMapApiInternals={true}
                heatmapLibrary={true}
                options={OPTIONS}
                onGoogleApiLoaded={({ map, maps }) => {
                    gMap = map;
                    mapsApi = maps
                }}>
            </GoogleMapReact>
        </div>
    );
}, (prevProps, nextProps) => { return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) });

export default Map;