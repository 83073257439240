import {
    AppBar,
    Box,
    Button,
    Dialog, DialogActions, DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel, MenuItem,
    Select,
    Slide,
    Switch,
    TextField,
    Toolbar,
    Typography,
    makeStyles,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    dialogContent: {
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(8)
        },
    }
}));

const _valid = {
    title: { val: true, text: "" },
    latitude: { val: true, text: "" },
    longitude: { val: true, text: "" },
    status: { val: true, text: "" },
    categoryId: { val: true, text: "" },
    sectorId: { val: true, text: "" }
};

export default function FormDialogPuntos(props) {
    const [data, setData] = React.useState({ ...props.data });
    const [validation, setValidation] = React.useState(_valid);
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const istitleValid = title => title !== null && title !== undefined && title !== '' && title.length <= 200;
    const isCategoryValid = categoryId => categoryId !== null && categoryId !== '';
    const isSectorValid = sectorId => sectorId !== null && sectorId !== '';
    const latsValid = latitude => latitude !== null && latitude !== undefined && latitude !== '';
    const lngValid = longitude => longitude !== null && longitude !== undefined && longitude !== '';
    const statusValid = status => status !== null && status !== undefined;

    const validateAll = (_data = null) => {
        const dataToValidate = (_data || data);
        const _istitleValid = handleFieldValidation(_data, dataToValidate.title, "title", istitleValid(dataToValidate.title), "Titulo Invalido");
        const _iscategoryIdValid = handleFieldValidation(_data, dataToValidate.categoryId, "categoryId", isCategoryValid(dataToValidate.categoryId), "Categoria inválida");
        const _issectorIdValid = handleFieldValidation(_data, dataToValidate.sectorId, "sectorId", isSectorValid(dataToValidate.sectorId), "Sector inválida");
        const _arelatValid = handleFieldValidation(_data, dataToValidate.latitude, "latitude", latsValid(dataToValidate.latitude), "Latitud inválidos");
        const _arelngValid = handleFieldValidation(_data, dataToValidate.longitude, "longitude", lngValid(dataToValidate.longitude), "Longitud inválidos");
        const _areStatusValid = handleFieldValidation(_data, dataToValidate.status, "status", statusValid(dataToValidate.status), "Estado inválidos");
        return _istitleValid && _iscategoryIdValid && _issectorIdValid && _arelatValid && _arelngValid && _areStatusValid;
    }

    const save = () => {
        if (!validateAll()) {
            return;
        }
        if (props.action === 'create') {
            props.saveHandler(data);
        } else {
            props.updateHandler(data);
        }
    }

    const handleFieldValidation = (_data, value, key, validationPredicate, errorText) => {
        setData({ ...(_data || data), [key]: value });
        const isValid = validationPredicate;
        const validationObject = isValid ? { val: true, text: "" } : { val: false, text: errorText };
        validation[key] = validationObject;
        setValidation(validation);
        return isValid;
    }


    const handleClose = () => {
        setData({ ...props.data });
        setValidation({
            title: { val: true, text: "" },
            latitude: { val: true, text: "" },
            longitude: { val: true, text: "" },
            status: { val: true, text: "" },
            categoryId: { val: true, text: "" },
            sectorId: { val: true, text: "" }
        });
        props.handleClose();
    }

    React.useEffect(() => {
        setData({ ...props.data });
        if (props.action === 'update') {
            validateAll(props.data);
        }
    }, [props.data])

    return (
        <div>
            <Dialog fullScreen={fullScreen} open={props.open} fullWidth={true}
                onClose={handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">
                            {props.action === 'create' ? 'Crear Puntos' : (props.action === 'update' ? 'Editar Puntos' : 'Ver Puntos')}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent className={classes.dialogContent}>
                    <React.Fragment>

                        <Grid item xs={12} mt={5}>
                            <TextField id="title" label="Titulo" placeholder="Ingresa un Titulo" fullWidth
                                margin="none" error={!validation.title.val} helperText={validation.title.text}
                                defaultValue={data.title} InputLabelProps={{ shrink: true }} style={{ marginTop: 0 }}
                                onChange={({ target }) => handleFieldValidation(null, target.value, "title", istitleValid(target.value), "Titulo inválido")} />
                        </Grid>
                        <FormControl style={{ width: '100%' }} margin='none'>
                            <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                value={data.categoryId}
                                onChange={({ target }) => handleFieldValidation(null, target.value, "categoryId", isCategoryValid(target.value), "Categoria inválida")}>
                                <MenuItem key={0} value={0}>{'Sin seleccionar'}</MenuItem>
                                {props.category.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                        <FormControl style={{ width: '100%' }} margin='none'>
                            <InputLabel id="demo-simple-select-label">Sector</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                value={data.sectorId}
                                onChange={({ target }) => handleFieldValidation(null, target.value, "sectorId", isSectorValid(target.value), "Sector inválida")}>
                                <MenuItem key={0} value={0}>{'Sin seleccionar'}</MenuItem>
                                {props.sector.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                        <div>
                            <Box mt={5}>
                            <Typography>Coordenadas XY *</Typography>
                                <Grid container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start" 
                                    spacing={2}>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={!validation.latitude.val}
                                            helperText={validation.latitude.text}
                                            margin="dense"
                                            id="latitude"
                                            defaultValue={data.latitude}
                                            onChange={({ target }) => handleFieldValidation(null, target.value, "latitude", latsValid(target.value), "Latitud inválido")}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={!validation.longitude.val}
                                            helperText={validation.longitude.text}
                                            margin="dense"
                                            id="longitude"
                                            defaultValue={data.longitude}
                                            onChange={({ target }) => handleFieldValidation(null, target.value, "longitude", lngValid(target.value), "Longitud inválido")}
                                            type="text"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                        <div>
                            <FormControlLabel
                                style={{ marginLeft: 0, marginTop: 16, fontSize: 16 }}
                                control={
                                    <Switch
                                        checked={data.status}
                                        onChange={({ target }) => handleFieldValidation(null, target.checked, "status", statusValid(target.checked), "Estado inválido")}
                                        color="secondary"
                                    />
                                }
                                labelPlacement="start"
                                label="Estado"
                            />
                        </div>

                    </React.Fragment>
                </DialogContent>

                <DialogActions>
                    {
                        props.viewPoints
                            ? <React.Fragment>
                                <Button onClick={handleClose}>
                                    Cerrar
                                </Button>
                            </React.Fragment>
                            : <React.Fragment>
                                <Button onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button onClick={save} color="primary">
                                    Guardar
                                </Button>
                            </React.Fragment>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}