import React from 'react';
import { Switch } from "react-router-dom";

import PuntosView from 'components/views/PuntoView';
import SectorView from 'components/views/SectorView';
import AdminView from '../views/AdminView';
import AdvertisementView from '../views/AdvertisementView';
import AgentView from '../views/AgentView';
import DashboardView from '../views/DashboardView';
import HeatMapView from '../views/HeatMapView';
import IncidencesView from '../views/IncidencesView';
import LoginView from '../views/LoginView';
import MapaDenunciasView from '../views/MapMonitorView';
import StatisticsView from '../views/StatisticsView';
import UserView from '../views/UserView';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export default class Routes extends React.PureComponent {

  render() {
    return (
      <Switch>
        <PublicRoute path="/login" component={LoginView} />
        <PrivateRoute path="/(home|dashboard|)/" component={DashboardView} />
        <PrivateRoute path="/mapa" component={MapaDenunciasView} />
        <PrivateRoute path="/mapa-calor" component={HeatMapView} />
        <PrivateRoute path="/estadistica" component={StatisticsView} />
        <PrivateRoute path="/admins" component={AdminView} />
        <PrivateRoute path="/agentes" component={AgentView} />
        <PrivateRoute path="/usuarios" component={UserView} />
        <PrivateRoute path="/publicidad" component={AdvertisementView} />
        <PrivateRoute path="/incidencias" component={IncidencesView} />
        <PrivateRoute path="/sector" component={SectorView} />
        <PrivateRoute path="/puntos" component={PuntosView} />
      </Switch>
    );
  }

}