import { Button, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import axios from 'axios';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import React from 'react';
import { Link } from "react-router-dom";
import constants from '../../constants';
import { useAuth } from "../../context/auth";
import { useMapMonitor } from "../../context/mapMonitor";
import { useSnackBar } from "../../context/snackBar";
import GeneralDialog from '../common/GenericDialog';

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fieldLabel: {
        fontWeight: 600
    },
    fieldContainer: {
        margin: '4px 4px'
    },
    imgContainer: {
        width: '100%',
        height: '30vh',
        overflow: 'auto'
    },
    imgReport: {
        width: '100%',
        height: 'auto',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    headerContainer: {
        display: "flex",
        color: "white",
        background: 'gray',
        padding: '0 8px',
    },
    btnBack: {
        minWidth: "auto",
        marginTop: 0,
        color: "white",
        marginRight: 4
    },
    btnStatusSection: {
        display: "flex",
        justifyContent: 'center'
    },
    statusChip: {
        height: 20,
        color: 'white'
    }
}));

const Detail = React.memo((props) => {
    const { selectedItem, map, prevZoom, prevCoordinates } = useMapMonitor();
    const { setSelectedItem } = useMapMonitor();
    const height = props.height ? props.height + 'vh' : "auto";
    const classes = useStyles();
    const { auth } = useAuth();
    const { openSnackBar } = useSnackBar();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [title, setTitle] = React.useState(null);
    const [text, setText] = React.useState(null);
    const [dialogData, setDialogData] = React.useState(null);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': auth.token
    }

    function handleBackButton() {
        setSelectedItem(null);
        if (map != null && prevZoom != null) {
            map.panTo(prevCoordinates);
            map.setZoom(prevZoom);
        }
    }

    function requestUpdateReport(status) {
        setOpenDialog(false);
        return axios.put(constants.apiBase + "web/reports/" + selectedItem.item.id + "/status", { "status": status },
            { headers }).then(result => {
                const _selected = selectedItem;
                _selected.item.status = status;
                setSelectedItem(_selected);
                openSnackBar({ open: true, variant: 'success', text: 'Se modificó el estado del reporte  ' + _selected.item.id + ''})
                if (status == constants.reportStatus.DISCARDED.value) {
                    setSelectedItem(null);
                }
            }).catch(e => {
                console.error(e);
                openSnackBar({ open: true, variant: 'error', text: 'No se pudo modificar el estado del reporte' })
            });
    }

    function printBtnStatusSection() {
        if (selectedItem != null && selectedItem.item.status == constants.reportStatus.PENDING.value) {
            return (
                <div className={classes.btnStatusSection}>
                    <Button onClick={() => {
                        setOpenDialog(true);
                        setTitle('¿Desea marcar el reporte como atendido?');
                        setDialogData(constants.reportStatus.ATTENDED.value)
                    }} variant="contained" color="primary" style={{ width: 120, margin: 12 }}>
                        Atendido
                </Button>
                    <Button onClick={() => {
                        setOpenDialog(true);
                        setTitle('¿Desea marcar el reporte como descartado?');
                        setDialogData(constants.reportStatus.DISCARDED.value)
                    }} variant="contained" color="secondary" style={{ width: 120, margin: 12 }}>
                        Descartar
                </Button>
                </div>
            )
        }
    }

    function printDialog() {
        if (openDialog) {
            return (
                <GeneralDialog open={openDialog} title={title} text={text} handleConfirmation={requestUpdateReport}
                    handleClose={() => { setOpenDialog(false) }} data={dialogData} />
            )
        }
    }

    function getColor(item) {
        return item.incidence.severity == constants.reportSeverity.HIGHT.value ? constants.reportSeverity.HIGHT.color :
            item.incidence.severity == constants.reportSeverity.MEDIUM.value ? constants.reportSeverity.MEDIUM.color :
                constants.reportSeverity.LOW.color;
    }

    function getText(item) {
        return item.incidence.severity == constants.reportSeverity.HIGHT.value ? constants.reportSeverity.HIGHT.text :
            item.incidence.severity == constants.reportSeverity.MEDIUM.value ? constants.reportSeverity.MEDIUM.text :
                constants.reportSeverity.LOW.text;
    }

    return (
        <div style={{ height: selectedItem != null ? height : '0px', overflowY: "auto", overflowX: 'hidden' }}>
            
            {selectedItem != null ?
                <div className={classes.headerContainer}>
                    <Button className={classes.btnBack} onClick={handleBackButton}><ArrowBackIcon /></Button>
                    <p>{selectedItem.type == 'report' ? 'Reporte' : selectedItem.type == 'point' ? 'Punto Municipal' : 'Agente'}</p>
                </div>
                : ''}
            {selectedItem != null && selectedItem.type == 'report' ?
                <div style={{ display: "flex", flexDirection: "column", margin: 12 }}>
                    <div className={classes.fieldContainer}>
                        <span className={classes.fieldLabel}>Codigo:</span> {selectedItem.item.id}
                    </div>
                    <div className={classes.fieldContainer}>
                        <span className={classes.fieldLabel}>Categoria:</span> {selectedItem.item.category.title}
                    </div>
                    <div className={classes.fieldContainer}>
                        <span className={classes.fieldLabel}>Incidencia:</span> {selectedItem.item.incidence != null ? selectedItem.item.incidence.title : 'Sin especificar'}
                    </div>
                    <div className={classes.fieldContainer}>
                        <span className={classes.fieldLabel}>Direccion:</span> {selectedItem.item.address}
                    </div>
                    <div className={classes.fieldContainer}>
                        <span className={classes.fieldLabel}>Reportado por:</span> <Link to={'/usuarios?id=' + selectedItem.item.account.id}>{selectedItem.item.account.firstName + ' ' + selectedItem.item.account.lastName}</Link>
                    </div>
                    <div className={classes.fieldContainer}>
                        <span className={classes.fieldLabel}>Fecha de reporte:</span> {format(fromUnixTime(selectedItem.item.createdAt / 1000), 'dd/MM/yyyy h:mm:ss a')}
                    </div>
                    <div className={classes.fieldContainer}>
                        <span className={classes.fieldLabel}>Severidad: </span>
                        {selectedItem.item.incidence != null ?
                            <Chip label={getText(selectedItem.item)} variant="outlined"
                                className={classes.statusChip} style={{ fontWeight: 600, backgroundColor: getColor(selectedItem.item) }} /> : 'Sin especificar'
                        }
                    </div>
                    <div className={classes.fieldContainer}>
                        <span className={classes.fieldLabel}>Estado: </span>
                        <Chip label={constants.reportStatus[selectedItem.item.status].text} variant="outlined"
                            className={classes.statusChip} style={{ fontWeight: 600, backgroundColor: constants.reportStatus[selectedItem.item.status].color }} />
                    </div>
                    {selectedItem.item.comment ?
                        <div className={classes.fieldContainer}>
                            <span className={classes.fieldLabel}>Comentario:</span> {selectedItem.item.comment}
                        </div> : ''
                    }
                </div> : ''
            }
            {selectedItem != null && selectedItem.item.photoUrl != null && selectedItem.item.photoUrl != '' ?
                <div className={classes.imgContainer}>
                    <img className={classes.imgReport} src={constants.urlBase + selectedItem.item.photoUrl} alt=""
                        onClick={() => window.open(constants.urlBase + selectedItem.item.photoUrl, "_blank", "width=600,height=600,top=120,left=120")} />
                </div> : ''
            }
            {selectedItem != null && selectedItem.type == 'agent' ?
                <div style={{ display: "flex", flexDirection: "column", margin: 16 }}>
                    <div>
                        <span className={classes.fieldLabel}>Nombre:</span> {selectedItem.item.account.firstName} {selectedItem.item.account.lastName}
                    </div>
                    <div>
                        <span className={classes.fieldLabel}>Email:</span> {selectedItem.item.account.email}
                    </div>
                    <div>
                        <span className={classes.fieldLabel}>Teléfono:</span> {selectedItem.item.account.user.phone != null ? selectedItem.item.account.user.phone : 'Sin especificar'}
                    </div>
                    <div>
                        <span className={classes.fieldLabel}>Última actualización:</span> {format(fromUnixTime(selectedItem.item.updatedAt / 1000), 'dd/MM/yyyy h:mm:ss a')}
                    </div>
                </div> : ''
            }
            {
                selectedItem != null && selectedItem.type == 'point' ?
                <div style={{ display: "flex", flexDirection: "column", margin: 16 }}>
                    <div>
                        <span className={classes.fieldLabel}>titulo :</span> {selectedItem.item.title}
                    </div>
                    <div>
                        <span className={classes.fieldLabel}>Categoria :</span> {selectedItem.item.categorys}
                    </div>
                    <div>
                        <span className={classes.fieldLabel}>Sector :</span> {selectedItem.item.sectors}
                    </div>
                </div> : ''   
            }
            {printBtnStatusSection()}
            {printDialog()}
        </div>
    );
});

export default Detail;