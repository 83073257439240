import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, TableSortLabel, Badge } from '@material-ui/core';
import constants from '../../constants';

const headCells = [
  { id: 'num', numeric: false, label: '#', order: false },
  { id: 'title', numeric: false, label: 'Titulo', order: true },
  { id: 'shortTitle', numeric: false, label: 'Titulo corto', order: true },
  { id: 'severity', numeric: true, label: 'Severidad', order: true },
  { id: 'categoryTitle', numeric: false, label: 'Categoría', order: true }
];

function desc(a, b, orderBy) {
  try {
    if (b[orderBy].toString().toUpperCase() < a[orderBy].toString().toUpperCase()) {
      return -1;
    }
    if (b[orderBy].toString().toUpperCase() > a[orderBy].toString().toUpperCase()) {
      return 1;
    }
    return 0;
  } catch (e) {
    return 0;
  }
}

const AgentDataTable = (props) => {
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const handleClick = (event, row) => {
    props.clickRow(row);
  };

  return (
    <React.Fragment>
      <Table aria-label="lista agentes">
        <TableHead>
          <TableRow>
            {headCells.map(headCell => (
              <TableCell
                key={headCell.id}
                align="center"
                sortDirection={orderBy === headCell.id ? order : false}>
                {headCell.order ?
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={order}
                    onClick={createSortHandler(headCell.id)}>
                    {headCell.label}
                  </TableSortLabel>
                  : headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(props.data, getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow
                  style={{ cursor: 'pointer' }}
                  hover
                  onClick={event => handleClick(event, row)}
                  tabIndex={-1}
                  key={row.id}>
                  <TableCell component="th" id={row.id} scope="row" align="center">{index + 1}</TableCell>
                  <TableCell align="center">{row.title}</TableCell>
                  <TableCell align="center">{row.shortTitle}</TableCell>
                  <TableCell align="center">{row.severity == constants.reportSeverity.HIGHT.value ? constants.reportSeverity.HIGHT.text :
                    row.severity == constants.reportSeverity.MEDIUM.value ? constants.reportSeverity.MEDIUM.text : constants.reportSeverity.LOW.text}</TableCell>
                  <TableCell align="center">{row.categoryTitle}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
}

export default AgentDataTable;