import { Grid } from "@material-ui/core";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import constants from "constants";
import React from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    title: {
      display: false,
    },
  },
};


function calculateTotalBySectorsAndCategory(sectors, category, data) {
  return sectors.map(
    (_sector) =>{
      let result = data.filter(
        (_data) => _data.category === category && _data.sector === _sector
      )
      return  result.length > 0 ? result[0].total : [];
    }
  );
}

const StatisticsChart = (props) => {
  const [data, setData] = React.useState({ labels: [], datasets: [] });

  function generateData(apiData) {
    let labels = Array.from(new Set(apiData.map((item) => item.sector)));
    let dataLabels = Array.from(new Set(apiData.map((item) => item.category)));
    let datasets = dataLabels.map((item, index) => {
      return {
        label: item,
        data: calculateTotalBySectorsAndCategory(labels, item, apiData),
        backgroundColor:
        constants.colors.length > index ? constants.colors[index] : constants.randColor(),
      };
    });

    setData({
      labels: labels,
      datasets: datasets,
    });
  }

  React.useEffect(() => {
    generateData(props.data);
  }, [props.data]);

  return (
    <Grid item xs={12}>
      <div
        style={{
          minHeight: "50vh",
          padding: "5vh",
          width: "100%",
          minWidth: "720px",
        }}
      >
        <Bar options={options} data={data} />
      </div>
    </Grid>
  );
};

export default StatisticsChart;
