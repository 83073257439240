import {
  AppBar,
  Button,
  Dialog, DialogActions, DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel, MenuItem, Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import constants from '../../constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  dialogContent: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8)
    }
  }
}));

const validateObj = {
  title: { val: true, text: "" },
  shortTitle: { val: true, text: "" },
  categoryId: { val: true, text: "" },
  severity: { val: true, text: "" }
};

const IncidenceFormDialog = React.memo((props) => {
  const [data, setData] = React.useState(Object.assign({}, props.data))
  const [validation, setValidation] = React.useState(Object.assign({}, validateObj))
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [categoryId, setCategoryId] = React.useState(0)
  const [severity, setSeverity] = React.useState(0)

  const updateDataState = (key, value) => {
    const _data = Object.assign({}, data)
    _data[key] = value;
    setData(_data);
  }

  function handlePositiveAction() {
    if (validate()) {
      if (props.action === 'create') {
        props.handleCreate(data);
      } else {
        props.handleUpdate(data);
      }
    }
  }

  const handleCategorySelect = event => {
    const value = event.target.value;
    setCategoryId(value);

    const _data = Object.assign({}, data);
    _data.categoryId = value;
    setData(_data);
  }

  const handleSeveritySelect = event => {
    const value = event.target.value;
    setSeverity(value);

    const _data = Object.assign({}, data);
    _data.severity = value;
    setData(_data);
  }

  const handleStatusSwitch = event => {
    updateDataState("disabledAt", !event.target.checked ? new Date().getTime() : null);
  };

  function validate() {
    const valid = Object.assign({}, validateObj);
    let isValid = true;
    if (data.title.length < 1) {
      valid.title.val = false;
      valid.title.text = "El campo título es requerido."
      isValid = false;
    }
    if (data.shortTitle.length < 1) {
      valid.shortTitle.val = false;
      valid.shortTitle.text = "El campo titulo corto es requerido."
      isValid = false;
    }
    if (data.severity == null) {
      valid.severity.val = false;
      valid.severity.text = "El campo severidad es requerido."
      isValid = false;
    }
    if (data.categoryId == null) {
      valid.categoryId.val = false;
      valid.categoryId.text = "El campo categoria es requerido."
      isValid = false;
    }
    setValidation(valid);
    return isValid;
  }

  React.useEffect(() => {
    setData(Object.assign({}, props.data));
    if (props.data != null && props.categories != null) {
      setCategoryId(props.data.categoryId);
      setSeverity(props.data.severity)
    }
  }, [props.data])

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={props.open}
        onClose={props.handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {props.action === 'create' ? 'Crear incidencia' : 'Editar incidencia'}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent className={classes.dialogContent}>
          <TextField
            style={{ width: '100%', marginTop: 16 }}
            error={!validation.title.val}
            helperText={validation.title.text}
            required
            margin="dense"
            id="title"
            label="Titulo"
            type="text"
            defaultValue={data ? data.title : ''}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            fullWidth
          />
          <TextField
            style={{ width: '100%', marginTop: 16 }}
            error={!validation.shortTitle.val}
            helperText={validation.shortTitle.text}
            required
            margin="dense"
            id="shortTitle"
            label="Titulo corto"
            type="text"
            defaultValue={data ? data.shortTitle : ''}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            fullWidth
          />

          <FormControl style={{ width: '100%', marginTop: 16 }}>
            <InputLabel id="severity-select-label">Severidad *</InputLabel>
            <Select
              labelId="severity-select-label"
              value={severity}
              onChange={handleSeveritySelect}>
              <MenuItem key={constants.reportSeverity.HIGHT.value} value={constants.reportSeverity.HIGHT.value}>{constants.reportSeverity.HIGHT.text}</MenuItem>
              <MenuItem key={constants.reportSeverity.MEDIUM.value} value={constants.reportSeverity.MEDIUM.value}>{constants.reportSeverity.MEDIUM.text}</MenuItem>
              <MenuItem key={constants.reportSeverity.LOW.value} value={constants.reportSeverity.LOW.value}>{constants.reportSeverity.LOW.text}</MenuItem>
            </Select>
          </FormControl>

          <FormControl style={{ width: '100%', marginTop: 16 }}>
            <InputLabel id="category-select-label">Categoría *</InputLabel>
            <Select
              labelId="category-select-label"
              value={categoryId}
              onChange={handleCategorySelect}>
              {props.categories.map(item => (
                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
              ))}

            </Select>
          </FormControl>

          <FormControlLabel
            style={{ marginLeft: 0, marginTop: 20, fontSize: 16 }}
            control={
              <Switch
                value="checkedBanned"
                checked={!data.disabledAt}
                onChange={handleStatusSwitch}
                color="secondary"
              />
            }
            labelPlacement="start"
            label="Estado"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={props.handleClose}>
            Cancel
          </Button>
          <Button onClick={handlePositiveAction} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default IncidenceFormDialog;