import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AddLocation from '@material-ui/icons/AddLocation';
import BarChartIcon from '@material-ui/icons/BarChart';
import ExploreIcon from '@material-ui/icons/Explore';
import MapIcon from '@material-ui/icons/Map';
import PersonIcon from '@material-ui/icons/Person';
import PhotoIcon from '@material-ui/icons/Photo';
import SecurityIcon from '@material-ui/icons/Security';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ViewListIcon from '@material-ui/icons/ViewList';
import React from 'react';
import constants from '../../constants';

const MenuList = (props) => {
    const role = props.role;

    function generate() {
        let menu = [];
        if (role.id === 1) {
            menu.push(
                <ListItem key={1} button component="a" href="/admins">
                    <ListItemIcon>
                        <SupervisorAccountIcon />
                    </ListItemIcon>
                    <ListItemText primary="Administradores" />
                </ListItem>
            );
        }
        if (role.id === 1 || role.id === 2) {
            menu.push(
                <ListItem key={2} button component="a" href="/agentes">
                    <ListItemIcon>
                        <SecurityIcon />
                    </ListItemIcon>
                    <ListItemText primary="Agentes" />
                </ListItem>,
                <ListItem key={3} button component="a" href="/usuarios">
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Usuarios" />
                </ListItem>
            );
        }
        if (role.id === 1) {
            menu.push(
                <ListItem key={4} button component="a" href="/incidencias">
                    <ListItemIcon>
                        <ViewListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Incidencias" />
                </ListItem>
            );
        }
        if (role.id === 1 || role.id === 2 || role.id === 3) {
            menu.push(
                <ListItem key={5} button component="a" href="/mapa">
                    <ListItemIcon>
                        <ExploreIcon />
                    </ListItemIcon>
                    <ListItemText primary="Monitoreo" />
                </ListItem>,
                <ListItem key={6} button component="a" href="/estadistica">
                    <ListItemIcon>
                        <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Estadística" />
                </ListItem>,
                <ListItem key={7} button component="a" href="/mapa-calor">
                    <ListItemIcon>
                        <MapIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mapa Historico" />
                </ListItem>
            );
        }
        if (role.id === 1 || role.id === 2 || role.id === 4) {
            menu.push(
                <ListItem key={8} button component="a" href="/publicidad">
                    <ListItemIcon>
                        <PhotoIcon />
                    </ListItemIcon>
                    <ListItemText primary="Publicidad" />
                </ListItem>);
        }
        if (role.id === 1) {
            menu.push(
                <ListItem key={9} button component="a" href="/sector">
                    <ListItemIcon>
                        <AddLocation />
                    </ListItemIcon>
                    <ListItemText primary="Sectores" />
                </ListItem>,
                <ListItem key={10} button component="a" href="/puntos">
                    <ListItemIcon>
                        <img src={constants.urlBase+'storage/static/images/login/gps.png'} height={24} width={24} />
                    </ListItemIcon>
                    <ListItemText primary="Puntos" />
                </ListItem>
            );
        }
        return menu;
    }

    return (
        <React.Fragment>
            {generate()}
        </React.Fragment>
    )
}

export default MenuList;