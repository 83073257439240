import React from 'react';
import {
  makeStyles, useTheme, useMediaQuery, Button, TextField, Dialog, DialogActions, DialogContent, Slide,
  AppBar, Toolbar, IconButton, Typography, FormControlLabel, Switch
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  dialogContent: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8)
    }
  }
}));

export default function FormDialog(props) {
  const [data, setData] = React.useState(Object.assign({}, props.data))
  const [validation, setValidation] = React.useState(
    {
      firstName: { val: true, text: "" },
      lastName: { val: true, text: "" },
      dni: { val: true, text: "" },
      phone: { val: true, text: "" },
      email: { val: true, text: "" },
      address: { val: true, text: "" },
      password: { val: true, text: "" },
      confirmPassword: { val: true, text: "" }
    }
  )
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const updateDataState = (key, value) => {
    const _data = Object.assign({}, data)
    if (key === 'phone' || key === 'address' || key === 'dni') {
      _data.user[key] = value;
    } else {
      _data[key] = value;
    }
    setData(_data);
  }

  function handlePositiveAction() {
    if (validate()) {
      if (props.action === 'create') {
        props.handleCreate(data);
      } else {
        props.handleUpdate(data);
      }
    }
  }

  function validate() {
    const valid = {
      firstName: { val: true, text: "" },
      lastName: { val: true, text: "" },
      dni: { val: true, text: "" },
      phone: { val: true, text: "" },
      email: { val: true, text: "" },
      address: { val: true, text: "" },
      password: { val: true, text: "" },
      confirmPassword: { val: true, text: "" }
    };
    const emailRegex = /^\w+(\.-?\w+)*@\w+(\.-?\w+)*(\.\w{2,3})+$/;
    let isValid = true;

    if (data.firstName.length < 1) {
      valid.firstName.val = false;
      valid.firstName.text = "El campo nombre es requerido."
      isValid = false;
    }
    if (data.lastName.length < 1) {
      valid.lastName.val = false;
      valid.lastName.text = "El campo apellido es requerido."
      isValid = false;
    }
    if (data.user.dni != null && data.user.dni !== "") {
      if(data.user.dni.length < 8){
        valid.dni.val = false;
        valid.dni.text = "El campo dni no cumple con el formato requerido minimo 8 caracteres."
        isValid = false;
      }
    }
    if (data.email.length < 3 || !emailRegex.test(data.email)) {
      valid.email.val = false;
      valid.email.text = "El campo email no cumple con el formato requerido."
      isValid = false;
    }
    if (data.user.phone.length < 8 && data.user.phone.length > 15) {
      valid.phone.val = false;
      valid.phone.text = "El campo teléfono debe de contener entre 9 y 15 caracteres."
      isValid = false;
    }
    if (data.password != null &&data.password !== "") {
      if (data.password.length < 6) {
        valid.password.val = false;
        valid.password.text = "El campo contraseña debe de contener al menos 6 caracteres."
        isValid = false;
      } else if (data.password !== data.confirmPassword) {
        valid.confirmPassword.val = false;
        valid.confirmPassword.text = "El campo confirmar contraseña debe coincidir con el campo contraseña."
        isValid = false;
      }
    }
    setValidation(valid);
    return isValid;
  }

  const handleChangeSwitch = name => event => {
    updateDataState("banned", !event.target.checked)
    //setState({ ...state, [name]: event.target.checked });
  };

  React.useEffect(() => {
    setData(Object.assign({}, props.data));
  }, [props.data])

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={props.open}
        onClose={props.handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {props.action === 'create' ? 'Crear usuario' : 'Editar usuario'}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent className={classes.dialogContent}>
          <TextField
            error={!validation.firstName.val}
            helperText={validation.firstName.text}
            required
            margin="dense"
            id="firstName"
            label="Nombre"
            type="text"
            defaultValue={data ? data.firstName : ''}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            fullWidth
          />
          <TextField
            error={!validation.lastName.val}
            helperText={validation.lastName.text}
            required
            margin="dense"
            id="lastName"
            label="Apellidos"
            type="text"
            defaultValue={data ? data.lastName : ''}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            fullWidth
          />
          <TextField
            error={!validation.dni.val}
            helperText={validation.dni.text}
            required
            margin="dense"
            id="dni"
            label="N° DNI"
            type="text"
            defaultValue={data && data.user ? data.user.dni : ''}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            fullWidth
          />
          <TextField
            error={!validation.email.val}
            helperText={validation.email.text}
            required
            margin="dense"
            id="email"
            label="Correo"
            type="email"
            defaultValue={data ? data.email : ''}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            fullWidth
          />
          <TextField
            error={!validation.phone.val}
            helperText={validation.phone.text}
            required
            margin="dense"
            id="phone"
            label="Teléfono"
            type="text"
            defaultValue={data && data.user ? data.user.phone : ''}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            fullWidth
          />
          <TextField
            error={!validation.address.val}
            helperText={validation.address.text}
            margin="dense"
            id="address"
            label="Dirección"
            type="text"
            defaultValue={data && data.user ? data.user.address : ''}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            fullWidth
          />
          <TextField
            error={!validation.password.val}
            helperText={validation.password.text}
            margin="dense"
            id="password"
            label="Contraseña"
            type="password"
            defaultValue={null}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            fullWidth
          />
          <TextField
            error={!validation.confirmPassword.val}
            helperText={validation.confirmPassword.text}
            margin="dense"
            id="confirmPassword"
            label="Confirmar contraseña"
            defaultValue={null}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            type="password"
            fullWidth
          />

          <FormControlLabel
            style={{ marginLeft: 0, marginTop: 20, fontSize: 16 }}
            control={
              <Switch
                value="checkedBanned"
                checked={!data.banned}
                onChange={handleChangeSwitch('checkedBanned')}
                color="secondary"
                />
            }
            labelPlacement="start"
            label="Estado"
          />

        </DialogContent>

        <DialogActions>
          <Button onClick={props.handleClose}>
            Cancel
          </Button>
          <Button onClick={handlePositiveAction} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}