export default new class Account {
   
    firstName = ""
    lastName = ""
    user = {
        address: "",
        phone: "",
        dni : "",
    }
    admin = {
    }
    email= ""
    address = ""
    password = ""
    confirmPassword = ""
    bannedAt = ""
    roleId = null
    banned = false
    agentAccess= false
}()