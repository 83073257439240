import { Grid, Paper } from "@material-ui/core";
import Filtros from "components/statistics/Filtros";
import StatisticChart from "components/statistics/Statisticshart";
import React from "react";
import { useAuth } from "../../context/auth";
import Layout from "../common/Layout";


const StatisticsView = () => {
  const [statsData, setStatsData] = React.useState([]);
  const { auth } = useAuth();


function handleSetStatsData(data) {
  setStatsData(data);
}

React.useEffect(() => {

  });

  return (
    <React.Fragment>
      <Layout headerTitle="Estadísticas">
        {[1, 2, 3].includes(auth.account.admin.role.id) ? (
          <Grid container justifyContent="center">
            <Filtros setStatsData={handleSetStatsData} />
            <Grid item xs={12}>
              <Paper style={{overflow: 'auto'}}>
                <StatisticChart data={statsData} />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          "No tiene los privilegios requeridos"
        )}
      </Layout>
    </React.Fragment>
  );
};

export default StatisticsView;
