const appName = "Seguridad Municipalidad Samegua";
const baseUrl = process.env.NODE_ENV === 'production' ? 'https://samegua.seguridadapp.pe/' : 'https://samegua.seguridadapp.pe/';

const colors = [
  "rgb(57, 107, 196)",
  "rgb(243, 120, 38)",
  "rgb(104, 170, 59)",
  "rgb(254, 199, 40)",
  "rgb(76, 146, 210)",
  "rgb(167, 167, 167)",
];
export const randColor = () => {
  return (
    "#" +
    Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0")
      .toUpperCase()
  );
};
function compare( a, b ) {
  if ( a.name < b.name ){
    return -1;
  }
  if ( a.name > b.name ){
    return 1;
  }
  return 0;
}
export default {
  appName: appName,
  urlBase: baseUrl,
  apiBase: baseUrl + "api/",
  randColor: randColor,
  colors: colors,
  compare: compare,
  reportStatus: {
    PENDING: { value: "PENDING", text: "Pendiente", color: "#C4C4C4" },
    ATTENDED: { value: "ATTENDED", text: "Atendido", color: "#57db90" },
    DISCARDED: { value: "DISCARDED", text: "Descartado", color: "gray" },
  },
  reportSeverity: {
    HIGHT: { value: 3, color: "#E57373", text: "Alto" },
    MEDIUM: { value: 2, color: "#E08A5F", text: "Medio" },
    LOW: { value: 1, color: "#ffd17c", text: "Bajo" },
  },
  googleMapsApi: "AIzaSyCIlLpv7UvfY1peyhHZGL3So2qci0KIBzc",
  defaultMapsCenter:
    process.env.NODE_ENV === "production"
      ? { lat: -17.1804911, lng: -70.8974241 }
      : { lat: -17.1804808, lng: -70.9025739 },
};

export const ui = {
  drawerWidth: 232,
};
