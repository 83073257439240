import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import qs from 'qs';
import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import constants from '../../constants';
import { useAuth } from "../../context/auth";
import Layout from '../common/Layout';
import UserDataTable from '../user/UserDataTable';
import UserFormDialog from '../user/UserFormDialog';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles(theme => ({
  tableContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  }
}));

function findItem(id, array) {
  let item = null;
  for (let element of array) {
    if (element.id === id) {
      item = element;
      break;
    }
  }
  return item;
}

const UserView = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const { auth } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState('create');
  const [selectedItem, setSelectedItem] = useState(null);
  const query = qs.parse(props.location.search, {
    ignoreQueryPrefix: true
  });
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth.token
  }

  function requestAllAgents() {
    return axios.get(constants.apiBase + "web/users", {
      headers
    }).then(result => {
      return result.data.data;
    }).catch(e => {
      return [];
    });
  }

  function requestUpdate(agent) {
    return axios.put(constants.apiBase + "web/users/" + agent.id, agent, {
      headers
    }).then(() => {
      requestAllAgents().then(result => {
        setData(result);
        setDialogOpen(false);
        window.location.reload();
      });
    }).catch(e => {
    });
  }

  const handeUpdateAction = (item) => {
    item.banned = item.bannedAt ? true : false;
    setSelectedItem(item);
    setDialogAction("update");
    setDialogOpen(true);
  }

  const handleClose = (e) => {
    setDialogOpen(false);
  }

  React.useEffect(() => {
    if (auth.account.admin.role.id === 1||auth.account.admin.role.id === 2) {
      requestAllAgents().then(result => {
        setData(result);
        if (result != null && query != null && query.id != null) {
          setSelectedItem(findItem(query.id, result));
          setDialogAction("update");
          setDialogOpen(true);
        }
      });
    }
  }, []);

  return (
    <React.Fragment>
      {[1 , 2].includes(auth.account.admin.role.id) ?
        <Layout headerTitle="Usuarios">
          <Grid item xs={12}>
            <Paper className={classes.tableContainer}>
              <UserDataTable data={data} clickRow={handeUpdateAction} />
            </Paper>
          </Grid>
          <UserFormDialog open={dialogOpen} action={dialogAction} handleClose={handleClose} data={selectedItem}
            handleUpdate={requestUpdate} />
        </Layout>
        : <Redirect to="/" />}
    </React.Fragment>
  );
}

export default UserView;