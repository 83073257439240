import React from 'react';
import Card from './Card';
import { List } from '@material-ui/core';
import { useMapMonitor } from "../../context/mapMonitor";
import { makeStyles } from '@material-ui/core/styles';
import constants from '../../constants';

const useStyles = makeStyles(theme => ({
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        color: "black",
        background: '#f4f4f4',
        padding: '8px 16px',
    },
    subHeaderContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        color: "gray",
        background: '#f4f4f4',
        padding: '0px 12px',
        paddingBottom: 4,
        fontSize: 13
    },
    leyendItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 12,
        width: 36
    },
    statusLeyend: {
        width: 26,
        height: 26,
        borderRadius: '50%'
    }
}));

const CardContainer = React.memo((props) => {
    const data = props.data
    const height = props.height ? props.height : "0";
    const { selectedItem } = useMapMonitor();
    const classes = useStyles();

    const createCards = () => {
        let cards = [];
        data.forEach((item) => {
            cards.push(<Card key={item.id} item={item} />)
        })
        return cards;
    }

    return (
        <div style={{ overflow: 'hidden' }}>
            <div className={classes.headerContainer} style={{ height: selectedItem == null ? (height * 0.10) + 'vh' : '0px', display: selectedItem == null ? 'flex' : 'none' }}>
                <div className={classes.leyendItem}>
                    <div className={classes.statusLeyend} style={{ backgroundColor: constants.reportStatus.PENDING.color }}></div>{'\u00A0'}{constants.reportStatus.PENDING.text}
                </div>
                <div className={classes.leyendItem}>
                    <div className={classes.statusLeyend} style={{ backgroundColor: constants.reportStatus.ATTENDED.color }}></div>{'\u00A0'}{constants.reportStatus.ATTENDED.text}
                </div>
                <div className={classes.leyendItem}>
                    <div className={classes.statusLeyend} style={{ backgroundColor: constants.reportSeverity.LOW.color }}></div>{'\u00A0'}{constants.reportSeverity.LOW.text}
                </div>
                <div className={classes.leyendItem}>
                    <div className={classes.statusLeyend} style={{ backgroundColor: constants.reportSeverity.MEDIUM.color }}></div>{'\u00A0'}{constants.reportSeverity.MEDIUM.text}
                </div>
                <div className={classes.leyendItem}>
                    <div className={classes.statusLeyend} style={{ backgroundColor: constants.reportSeverity.HIGHT.color }}></div>{'\u00A0'}{constants.reportSeverity.HIGHT.text}
                </div>
            </div>
            <div className={classes.subHeaderContainer} style={{ display: selectedItem == null ? 'flex' : 'none' }}>
                <div><b>Total</b>: {data.length}</div>
            </div>
            <div style={{ height: selectedItem == null ? (height * 0.87) + 'vh' : '0px', overflow: 'auto', display: selectedItem == null ? 'inherit' : 'none' }}>
                <List style={{ background: "#f7f7f7", overflowY: "auto", overflowX: 'hidden' }}>
                    {createCards()}
                </List>
            </div>
        </div>
    );
});

export default CardContainer;