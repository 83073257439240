import {
  AppBar,
  Button,
  Dialog, DialogActions, DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel, MenuItem,
  Select,
  Slide,
  Switch,
  TextField,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import Account from '../../model/AccountDTO';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  dialogContent: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8)
    }
  }
}));

export default function FormDialog(props) {
  const [data, setData] = React.useState(Object.assign({ Account }, props.data))
  const [roleId, setRoleId] = React.useState(0)
  const [samegua] = React.useState(1);
  const [validation, setValidation] = React.useState(
    {
      firstName: { val: true, text: "" },
      lastName: { val: true, text: "" },
      email: { val: true, text: "" },
      rolId: { val: true, text: "" },
      password: { val: true, text: "" },
      confirmPassword: { val: true, text: "" }
    }
  )
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const updateDataState = (key, value) => {
    const _data = Object.assign({}, data)
    if (key === 'phone' || key === 'address') {
      _data.user[key] = value;
    } else {
      _data[key] = value;
    }
    setData(_data);
  }

  function handlePositiveAction() {
    if (validate()) {
      if (props.action === 'create') {
        props.handleCreate(data);
      } else {
        props.handleUpdate(data);
      }
    }
  }

  function validate() {
    const valid = {
      firstName: { val: true, text: "" },
      lastName: { val: true, text: "" },
      email: { val: true, text: "" },
      rolId: { val: true, text: "" },
      password: { val: true, text: "" },
      confirmPassword: { val: true, text: "" }
    };

    const emailRegex = /^\w+(\.-?\w+)*@\w+(\.-?\w+)*(\.\w{2,3})+$/;
    let isValid = true;

    if (data.firstName.length < 1) {
      valid.firstName.val = false;
      valid.firstName.text = "El campo nombre es requerido."
      isValid = false;
    }
    if (data.lastName.length < 1) {
      valid.lastName.val = false;
      valid.lastName.text = "El campo apellido es requerido."
      isValid = false;
    }
    if (data.email.length < 3 || !emailRegex.test(data.email)) {
      valid.email.val = false;
      valid.email.text = "El campo email no cumple con el formato requerido."
      isValid = false;
    }

    if (data.rolId < 1) {
      valid.rolId.val = false;
      valid.rolId.text = "Debe de seleccionar un rol"
      isValid = false;
    }

    if (data.password != null && data.password !== "") {
      if (data.password.length < 6) {
        valid.password.val = false;
        valid.password.text = "El campo contraseña debe de contener al menos 6 caracteres."
        isValid = false;
      } else if (data.password !== data.confirmPassword) {
        valid.confirmPassword.val = false;
        valid.confirmPassword.text = "El campo confirmar contraseña debe coincidir con el campo contraseña."
        isValid = false;
      }
    }
    setValidation(valid);
    return isValid;
  }

  const handleBannedSwitch = name => event => {
    updateDataState("banned", !event.target.checked)
  };

  const handleAgentSwitch = name => event => {
    updateDataState("agentAccess", event.target.checked)
  };

  const handleRoleSelect = event => {
    const value = event.target.value;
    setRoleId(value);
    updateDataState("roleId", value);
  }

  React.useEffect(() => {
    setData(Object.assign({}, props.data));
    if (props.data != null && props.data.admin != null && props.data.admin.role != null) {
      setRoleId(props.data.admin.role.id);
    }
  }, [props.data])

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={props.open}
        onClose={props.handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {props.action === 'create' ? 'Crear Perfiles' : 'Editar Perfiles'}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent className={classes.dialogContent}>
          <TextField
            error={!validation.firstName.val}
            helperText={validation.firstName.text}
            required
            margin="dense"
            id="firstName"
            label="Nombre"
            type="text"
            defaultValue={data ? data.firstName : ''}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            fullWidth
          />
          <TextField
            error={!validation.lastName.val}
            helperText={validation.lastName.text}
            required
            margin="dense"
            id="lastName"
            label="Apellidos"
            type="text"
            defaultValue={data ? data.lastName : ''}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            fullWidth
          />
          <TextField
            error={!validation.email.val}
            helperText={validation.email.text}
            required
            margin="dense"
            id="email"
            label="Correo"
            type="email"
            defaultValue={data ? data.email : ''}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            fullWidth
          />

          <FormControl style={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-label">Perfiles</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={roleId}
              onChange={handleRoleSelect}>
              <MenuItem key={0} value={0}>{'Sin seleccionar'}</MenuItem>
              {props.roles.map(item => (
                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
              ))}

            </Select>
          </FormControl>

          <TextField
            error={!validation.password.val}
            helperText={validation.password.text}
            margin="dense"
            id="password"
            label="Contraseña"
            type="password"
            defaultValue={null}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            fullWidth
          />
          <TextField
            error={!validation.confirmPassword.val}
            helperText={validation.confirmPassword.text}
            margin="dense"
            id="confirmPassword"
            label="Confirmar contraseña"
            defaultValue={null}
            onChange={(e) => { updateDataState(e.target.id, e.target.value) }}
            type="password"
            fullWidth
          />
          {samegua === 0 ? 
          <div>
          <FormControlLabel
            style={{ marginLeft: 0, marginTop: 12, fontSize: 16 }}
            control={
              <Switch
                checked={data.agentAccess}
                onChange={handleAgentSwitch('agentAccess')}
                color="primary"
              />
            }
            labelPlacement="start"
            label="Agente"
          />
        </div> : ''
          }
          <div>
            <FormControlLabel
              style={{ marginLeft: 0, marginTop: 16, fontSize: 16 }}
              control={
                <Switch
                  checked={!data.banned}
                  onChange={handleBannedSwitch('banned')}
                  color="secondary"
                />
              }
              labelPlacement="start"
              label="Estado"
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={props.handleClose}>
            Cancel
          </Button>
          <Button onClick={handlePositiveAction} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}