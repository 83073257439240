import React, { Fragment } from 'react';
import { ListItem, Card, CardContent, Avatar, Typography ,List} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMapMonitor } from "../../context/mapMonitor";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MessageIcon from '@material-ui/icons/Message';
import formatDistanceStrict from 'date-fns/formatDistanceStrict'
import fromUnixTime from 'date-fns/fromUnixTime'
import es from 'date-fns/locale/es';
import constants from '../../constants';

const useStyles = makeStyles(theme => ({
    listItem: { width: "100%", paddingLeft: theme.spacing(2), paddinRight: theme.spacing(2), borderRadius: '5px'},
    selected: {
        animation: `$myEffect 10000ms !important`,
        animationDelay: '2000ms !important'
      },
      "@keyframes myEffect": {
        "0%": {
          backgroundColor: '#57db90'
        },
        "100%": {
          backgroundColor: '#f7f7f7',
        }
      },
    card: { width: "100%", borderRadius: '5px', boxShadow: '4px 4px 2px lightGray' },
    cardContent: {
        display: "flex", justifyContent: "space-between", alignItems: "center",
        borderRadius: '5px',
        "&:last-child": { paddingBottom: 16 }
    },
    leftSection: { display: "flex", alignItems: "center" },
    rightSection: { display: "flex", alignItems: "center", flexDirection: 'column', alignItems: "center", fontSize: 12 },
    avatarSection: {},
    innerLeftSection: { display: "flex", marginLeft: theme.spacing(2), flexDirection: "column" },
    iconSection: { marginTop: 4 },
    iconContent: { fontSize: 13 },
    autorTitle: { fontSize: 13 },
    categoryTitle: { fontSize: 14 },
    incidenceTitle: { fontSize: 13 }
}));

function getColor(item) {
    return item.status == constants.reportStatus.ATTENDED.value ? constants.reportStatus.ATTENDED.color : item.incidence != null ?
        item.incidence.severity == constants.reportSeverity.HIGHT.value ? constants.reportSeverity.HIGHT.color :
            item.incidence.severity == constants.reportSeverity.MEDIUM.value ? constants.reportSeverity.MEDIUM.color :
                constants.reportSeverity.LOW.color : constants.reportStatus.PENDING.color;
}

const CardMonitor = React.memo((props) => {
    const classes = useStyles();
    const item = props.item;
    const { setSelectedItem } = useMapMonitor();
    const [selectedIndex, setSelectedIndex] = React.useState();
    

    function handleClick() {
        setSelectedItem({ item: item, type: 'report' });
        setSelectedIndex(item.id);
    }



    return (
        <Fragment>
            <ListItem key={item.id} button onClick={handleClick} className={classes.listItem && selectedIndex === item.id ? classes.selected : ''}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <div className={classes.leftSection}>
                            <Avatar alt={item.category.title} className={classes.avatarSection}
                                style={{ width: item.id < 1000 ? 42 : "auto", height: item.id < 10000 ? 42 : 50, backgroundColor: getColor(item) }}>{item.id}</Avatar>
                            <div className={classes.innerLeftSection}>
                                <div className={classes.categoryTitle}>
                                    {item.category.title}
                                </div>
                                <div className={classes.incidenceTitle}>
                                    {item.incidence ? item.incidence.title : 'Sin especificar'}
                                </div>
                                <div className={classes.autorTitle} className="text-mutted">
                                    {item.account.firstName + " " + item.account.lastName}
                                </div>
                            </div>
                        </div>
                        <div className={classes.rightSection}>
                            {formatDistanceStrict(fromUnixTime(item.createdAt / 1000), new Date(), { locale: es, addSuffix: false, includeSeconds: true })}
                            <div className={classes.iconSection}>
                                {item.photoUrl != null && item.photoUrl != '' ? <AttachFileIcon className={classes.iconContent} /> : ''}
                                {item.comment != null && item.comment != '' ? <MessageIcon className={classes.iconContent} /> : ''}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </ListItem>
        </Fragment>
    );
});

export default CardMonitor;