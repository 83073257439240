import {
  Button,
  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle,
  Slide,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import axios from 'axios';
import qs from 'qs';
import React, { useState } from 'react';
import constants from '../../constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const PasswordResetFormDialog = (props) => {
  const [email, setEmail] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);
  const [validation, setValidation] = React.useState(
    {
      email: { val: true, text: "" }
    }
  )

  function validate() {

    const valid = {
      email: { val: true, text: "" }
    };

    const emailRegex = /^\w+(\.-?\w+)*@\w+(\.-?\w+)*(\.\w{2,3})+$/;
    let isValid = true;

    if (email.length < 3 || !emailRegex.test(email)) {
      valid.email.val = false;
      valid.email.text = "El campo email no cumple con el formato requerido."
      isValid = false;
    }

    setValidation(valid);
    return isValid;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };
  
  function requestPassword(email) {
    let _data = qs.stringify({
      email: email
    });
    return axios({
      method: 'post',
      url: constants.apiBase + "auth/password-resets",
      data: _data,
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
      }
    }).then(result => {
      props.handleClose();
      handleClickOpen();
    }).catch(e => {
      console.error(e);
      return null;
    });
  }

  function handlePositiveAction() {
    if (validate()) {
      requestPassword(email);
    }
  }


  return (
    <div>
      <Dialog fullScreen={fullScreen} open={props.open}
        onClose={props.handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
        <DialogTitle id="form-dialog-title">Restablece tu contraseña</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ingresa tu correo electrónico para realizar el proceso de restablecer tu contraseña.
          </DialogContentText>
          <TextField
            error={!validation.email.val}
            helperText={validation.email.text}
            required
            margin="dense"
            id="email"
            label="Correo"
            type="email"
            name="email"
            value={email}
            onChange={e => { setEmail(e.target.value) }}
            fullWidth
          />

          <DialogActions>
            <Button onClick={props.handleClose}>
              Cancelar
              </Button>
            <Button onClick={handlePositiveAction} color="primary">
              Enviar
              </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Restablece tu contraseña</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Hemos enviado un correo electrónico
            si no ves el correo electrónico en tu bandeja de entrada,
            revisa otros lugares donde podria estar, como tus carpetas
            de correo no deseado, sociales u otras.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

};


export default PasswordResetFormDialog;