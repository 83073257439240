import GoogleMapReact from 'google-map-react';
import React from 'react';
import constants from '../../constants';
import { useMapMonitor } from "../../context/mapMonitor";

let serviceZonePolygons = [];
let sectorLabelMarkers = [];

const OPTIONS = {
    minZoom: 12,
    maxZoom: 18,
    streetViewControl: true
}

export const ReportMarker = React.memo(({ isSelected, item }) => {
    const { setSelectedItem } = useMapMonitor();
    const color = isSelected ? 'black' : item.status == constants.reportStatus.ATTENDED.value ? constants.reportStatus.ATTENDED.color : item.incidence != null ?
        item.incidence.severity == constants.reportSeverity.HIGHT.value ? constants.reportSeverity.HIGHT.color :
            item.incidence.severity == constants.reportSeverity.MEDIUM.value ? constants.reportSeverity.MEDIUM.color :
                constants.reportSeverity.LOW.color : constants.reportStatus.PENDING.color;
    const size = isSelected ? 68 : 54;

    const handleSelectedItem = (item) => {
        setSelectedItem({ item: item, type: 'report' });
    }

    return (
        <div onClick={(e) => { handleSelectedItem(item) }} style={{
            zIndex: isSelected ? 3 : 1, borderRadius: '50%', background: color, width: size, height: size, display: 'flex',
            justifyContent: 'center', alignItems: 'center', position: 'absolute', transform: 'translate(-50%, -50%)'
        }}>
            <span style={{ color: 'white', fontSize: 20 }}>{item.id}</span>
        </div>
    )
}, (prevProps, nextProps) => { return JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) && prevProps.isSelected == nextProps.isSelected });

const AgentMarker = React.memo(({ isSelected, item }) => {
    const { setSelectedItem } = useMapMonitor();
    const size = isSelected ? 52 : 38;

    const handleSelectedItem = (item) => {
        setSelectedItem({ item: item, type: 'agent' });
    }

    return (
        <div onClick={(e) => { handleSelectedItem(item) }} style={{
            zIndex: 3, backgroundImage: `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqyro_MAizOdpotBsF6CrbdlyeRVyzFxqYFQ&usqp=CAU')`, width: size, height: size, display: 'flex',
            justifyContent: 'center', alignItems: 'center', position: 'absolute', transform: 'translate(-50%, -100%)', backgroundSize: "cover"
        }}>
            <span style={{ color: 'white', fontSize: 20 }}>{item.id}</span>
        </div>
    )
});

const PointsMarker = React.memo(({ isSelected, item }) => {
    const { setSelectedItem } = useMapMonitor();
    const size = isSelected ? 52 : 38;

    const handleSelectedItem = (item) => {
        setSelectedItem({ item: item, type: 'point' });
    }

    return (
        <div onClick={(e) => { handleSelectedItem(item) }} style={{
            zIndex: 3, backgroundImage: `url('${constants.urlBase + item.categorysUrl}')`, width: size, height: size, display: 'flex',
            justifyContent: 'center', alignItems: 'center', position: 'absolute', transform: 'translate(-50%, -100%)', backgroundSize: "cover"
        }}>
            <span style={{ color: 'white', fontSize: 20 }}>{item.id}</span>
        </div>
    )
});
let prevSelected = null;

const createMarkers = (data, selectedItem) => {

    const markers = [];
    data.forEach((item) => {
        if (item.status != constants.reportStatus.DISCARDED) {
            markers.unshift(
                <ReportMarker
                    key={item.id}
                    lat={item.latitude}
                    lng={item.longitude}
                    item={item}
                    isSelected={selectedItem != null && selectedItem.type == 'report' && selectedItem.item.id == item.id ? true : false}
                />
            )
        }
    });
    return markers;
}

const createAgentsMarkers = (positions, selectedItem) => {
    const agentsMarkers = [];
    positions.forEach((item) => {
        agentsMarkers.push(
            <AgentMarker
                key={'agent-' + item.accountId}
                lat={item.latitude}
                lng={item.longitude}
                item={item}
                isSelected={selectedItem != null && selectedItem.type == 'agent' && selectedItem.item.accountId == item.accountId ? true : false}
            />
        )
    });
    return agentsMarkers;
}

const createPointsMarkers = (points, selectedItem) => {
    const pointsMarkers = [];
    points.forEach((item) => {
        pointsMarkers.push(
            <PointsMarker
                key={'point-' + item.id}
                lat={item.latitude}
                lng={item.longitude}
                item={item}
                isSelected={selectedItem != null && selectedItem.type == 'point' && selectedItem.item.accountId == item.id ? true : false}
            />
        )
    });
    return pointsMarkers;
}


const Map = React.memo((props) => {
    const data = props.data;
    const positions = props.positions;
    const points = props.points;
    const { selectedItem, map, mapApi, setPrevZoom, setMap, setMapApi, setPrevCoordinates, serviceZones, showSectors } = useMapMonitor();

    const handleSelectedItem = () => {
        if (selectedItem == null || selectedItem.item == null || map == null || (prevSelected != null && selectedItem.item.id == prevSelected.item.id)) {
            return;
        }
        setPrevZoom(map.getZoom());
        setPrevCoordinates(map.getCenter());

        map.panTo({ lat: parseFloat(selectedItem.item.latitude), lng: parseFloat(selectedItem.item.longitude) });
        if (map.getZoom() < 18) {
            mapApi.event.addListenerOnce(map, 'idle', function () {
                map.setZoom(18);
            });
        }
        prevSelected = selectedItem;
    }

    const getSectorsRandomColors = (sectorPoints) => {
        let randomColors = [];
        let duplicates = true;

        while (duplicates) {
            randomColors = sectorPoints.map((_, index) => constants.colors.length > index ? constants.colors[index] : constants.randColor());
            duplicates = randomColors.some((color, index) => randomColors.indexOf(color) !== index);
        }

        return randomColors;
    }

    const handleServiceZones = () => {
        if (!map || !mapApi) {
            return;
        }

        if (!showSectors) {
            serviceZonePolygons.forEach(sectorPolygon => sectorPolygon.setMap(null));
            serviceZonePolygons = [];
            sectorLabelMarkers.forEach(sectorLabelMarker => sectorLabelMarker.setMap(null));
            sectorLabelMarkers = [];
            return;
        }

        const fatherSectorPoints = JSON.parse(serviceZones.points)
            .map(({ lat, lng }) => ({ lat: Number(lat), lng: Number(lng) }));

        const fatherSectorPolygon = new mapApi.Polygon({
            paths: fatherSectorPoints,
            strokeColor: "#FF0000",
            strokeOpacity: 1,
            strokeWeight: 1.9,
            fillColor: "#FF0000",
            fillOpacity: 0
        })

        fatherSectorPolygon.setMap(map);
        serviceZonePolygons.push(fatherSectorPolygon);

        
        const invisibleIcon = new mapApi.MarkerImage(
            "/invisible.PNG",
            null, /* size is determined at runtime */
            null, /* origin is 0,0 */
            null, /* anchor is bottom center of the scaled image */
            new mapApi.Size(32, 32)
        );

        const sectorPoints = serviceZones.sectors.map(sector => ({ ...sector, points: JSON.parse(sector.points) }))
            .map(sector => ({ ...sector, points: sector.points.map(({ lat, lng }) => ({ lat: Number(lat), lng: Number(lng) })) }))
            .sort(constants.compare);

        const randomColors = getSectorsRandomColors(sectorPoints);

        sectorPoints.forEach((sector, index) => {
            const color = randomColors[index];
            const sectorPolygon = new mapApi.Polygon({
                paths: sector.points,
                strokeColor: color,
                strokeOpacity: 0.9,
                strokeWeight: 0.6,
                fillColor: color,
                fillOpacity: 0.09
            });

            sectorPolygon.setMap(map);
            serviceZonePolygons.push(sectorPolygon);

            const bounds = new mapApi.LatLngBounds();
            sector.points.forEach(point => bounds.extend(point));
            const polygonCenter = bounds.getCenter();

            const labelMarker = new mapApi.Marker({
                position: polygonCenter,
                label: {
                    text: sector.name,
                    color: '#192841',
                    fontSize: '22px',
                    fontWeight: "bold"
                },
                map,
                icon: invisibleIcon
            });

            sectorLabelMarkers.push(labelMarker);
        });
    }

    React.useEffect(() => {
        handleSelectedItem()
    }, [selectedItem, data, mapApi]);

    React.useEffect(() => {
        handleServiceZones();
    }, [serviceZones, showSectors]);

    return (
        <div id="mapContainer" style={{ height: props.height + 'vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: constants.googleMapsApi }}
                defaultCenter={constants.defaultMapsCenter}
                defaultZoom={15.7}
                yesIWantToUseGoogleMapApiInternals={true}
                hoverDistance={0}
                options={OPTIONS}
                onGoogleApiLoaded={({ map, maps }) => {
                    setMap(map);
                    setMapApi(maps);
                }}>
                {createMarkers(data, selectedItem)}
                {createAgentsMarkers(positions, selectedItem)}
                {createPointsMarkers(points, selectedItem)}
            </GoogleMapReact>
        </div>
    );
}, (prevProps, nextProps) => { return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) && JSON.stringify(prevProps.positions) === JSON.stringify(nextProps.positions) && JSON.stringify(prevProps.points) === JSON.stringify(nextProps.points)})

export default Map;