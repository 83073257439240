import React from 'react';
import {
    makeStyles, useTheme, useMediaQuery, Button, TextField, Dialog, DialogActions, DialogContent, Slide,
    AppBar, Toolbar, IconButton, Typography, FormControlLabel, Grid, TextareaAutosize
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    dialogContent: {
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(8)
        },
    }
}));

const _useStyles = makeStyles(theme => ({
    disabledInput: {
        color: theme.palette.text.primary,
    },
}));

const _valid = {
    name: { val: true, text: "" },
    description: { val: true, text: "" },
    points: { val: true, text: "" },
};

export default function SectorFormDialog(props) {
    const [data, setData] = React.useState({ ...props.data });
    const [validation, setValidation] = React.useState(_valid);
    const theme = useTheme();
    const classes = useStyles();
    const _classes = _useStyles()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const isNameValid = name => name !== null && name !== undefined && name !== '' && name.length <= 30;
    const isDescriptionValid = description => description !== null && description !== undefined && description !== '' && description.length <= 250;
    const arePointsValid = points => points !== null && points !== undefined && points !== '';

    const validateAll = (_data = null) => {
        const dataToValidate = (_data || data);
        const _isNameValid = handleFieldValidation(_data, dataToValidate.name, "name", isNameValid(dataToValidate.name), "Nombre inválido");
        const _isDescriptionValid = handleFieldValidation(_data, dataToValidate.description, "description", isDescriptionValid(dataToValidate.description), "Descripción inválida");
        const _arePointsValid = handleFieldValidation(_data, dataToValidate.points, "points", arePointsValid(dataToValidate.points), "Puntos inválidos");
        return _isNameValid && _isDescriptionValid && _arePointsValid;
    }

    const save = () => {
        if (!validateAll()) {
            return;
        }

        if (props.action === 'create') {
            props.saveHandler(data);
        } else {
            props.updateHandler(data);
        }
    }

    const handleFieldValidation = (_data, value, key, validationPredicate, errorText) => {
        setData({ ...(_data || data), [key]: value });

        const isValid = validationPredicate;
        const validationObject = isValid ? { val: true, text: "" } : { val: false, text: errorText };
        validation[key] = validationObject;

        setValidation(validation);

        return isValid;
    }

    const handleClose = () => {
        setData({ ...props.data });
        setValidation({
            name: { val: true, text: "" },
            description: { val: true, text: "" },
            points: { val: true, text: "" },
        });
        props.handleClose();
    }

    React.useEffect(() => {
        setData({ ...props.data });
        if (props.action === 'update') {
            validateAll(props.data);
        }
    }, [props.data])

    return (
        <div>
            <Dialog fullScreen={fullScreen} open={props.open} fullWidth={true}
                onClose={handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition}>
                <AppBar>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">
                            {props.action === 'create' ? 'Crear Sector' : (props.action === 'update' ? 'Editar Sector' : 'Ver Puntos')}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent className={classes.dialogContent}>
                    {
                        props.viewPoints
                            ? <React.Fragment>
                                <Grid item xs={12}>
                                    <TextField
                                        rows={18}
                                        label="Points"
                                        multiline
                                        placeholder="Ingresa puntos"
                                        defaultValue={data.points}
                                        disabled={true}
                                        style={{ width: '100%', marginTop: '8px' }}
                                        InputProps={{ classes: { disabled: _classes.disabledInput } }}
                                    />
                                </Grid>
                            </React.Fragment>
                            : <React.Fragment>
                                <Grid item xs={12}>
                                    <TextField id="name" label="Nombre" placeholder="Ingresa un nombre" fullWidth 
                                        margin="normal" error={!validation.name.val} helperText={validation.name.text}
                                        defaultValue={data.name} InputLabelProps={{ shrink: true }} style={{ marginTop: 0 }}
                                        onChange={({ target }) => handleFieldValidation(null, target.value, "name", isNameValid(target.value), "Nombre inválido")} />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField id="description" label="Descripción" placeholder="Ingresa una descripción" fullWidth
                                        margin="normal" error={!validation.description.val} helperText={validation.description.text}
                                        defaultValue={data.description} InputLabelProps={{ shrink: true }} style={{ marginTop: '8px' }}
                                        onChange={({ target }) => handleFieldValidation(null, target.value, "description", isDescriptionValid(target.value), "Descripción inválida")} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        rows={10}
                                        label="Points"
                                        multiline
                                        placeholder="Ingresa puntos"
                                        defaultValue={data.points}
                                        error={!validation.points.val} helperText={validation.points.text}
                                        style={{ width: '100%', marginTop: '8px' }}
                                        onChange={({ target }) => handleFieldValidation(null, target.value, "points", arePointsValid(target.value), "Puntos inválidos")}
                                    />
                                </Grid>
                            </React.Fragment>
                    }
                </DialogContent>

                <DialogActions>
                    {
                        props.viewPoints
                            ? <React.Fragment>
                                <Button onClick={handleClose}>
                                    Cerrar
                                </Button>
                            </React.Fragment>
                            : <React.Fragment>
                                <Button onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button onClick={save} color="primary">
                                    Guardar
                                </Button>
                            </React.Fragment>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}