import DateFnsUtils from '@date-io/date-fns';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    Paper,
    Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import es from 'date-fns/locale/es';
import subMonths from 'date-fns/subMonths';
import React from 'react';
import constants from '../../constants';
import { useAuth } from "../../context/auth";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const useStyles = makeStyles(theme => ({
    paperContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: 8,
        minHeight: '8vh',
    },
    formControl: {
        margin: 4,
        width: '100%'
    },
    dateInputLabel: { marginRight: 16, fontSize: "13px", color: 'rgba(0, 0, 0, 0.54)', width: '100%' }
}));

const listStatus = [
    constants.reportStatus.ATTENDED,
    constants.reportStatus.DISCARDED,
    constants.reportStatus.PENDING
];
let allIncidences = [];
let allSectores = [];

const sinAsignar = { id: 0, title: 'Sin especificar' };

const Filtros = React.memo((props) => {
    const { auth } = useAuth();
    const [dataCatIncidences, setDataCatIncidences] = React.useState([]);
    const [sectores, setSectores] = React.useState([]);
    const classes = useStyles();
    const [startDate, setStartDate] = React.useState(subMonths(new Date(), 3));
    const [endDate, setEndDate] = React.useState(new Date());
    const [selIncidences, setSelIncidences] = React.useState([]);
    const [selStatus, setSelStatus] = React.useState([]);
    const [selSector, setSelSector] = React.useState([]);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': auth.token
    }

    function onDateStartChange(date) {
        setStartDate(date);
    }

    function onDateEndChange(date) {
        setEndDate(date);
    }

    function handleIncidencesChange(event) {
        if (event.target.value != null) {
            setSelIncidences(event.target.value);
        }
    }

    function handleChangeSelStatus(event) {
        setSelStatus(event.target.value);
    }

    const handleChangeSelSector = (event) => {
        if (event.target.value != null) {
            setSelSector(event.target.value);
        }
    };

    function requestExportExcel(filter) {
        return axios.post(constants.apiBase + "web/reports/exports-excel", filter, {
            headers,
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.xls');
            document.body.appendChild(link);
            link.click();
        }).catch(e => {
            console.error(e);
            return [];
        });
    }

    function requestZoneStatistics(filter) {
        return axios.post(constants.apiBase + "web/sectors/sectorCategoryStats", filter, {
            headers
        }).then(result => {
            return result.data.data;
        }).catch(e => {
            return [];
        });
    }

    function requestCatIncidences() {
        return axios.get(constants.apiBase + "web/categories/listForFilter", {
            headers
        }).then(result => {
            return result.data.data;
        }).catch(e => {
            console.error(e);
            return [];
        });
    }

    function requestSectores() {
        return axios.get(constants.apiBase + "web/sectors", {
            headers
        }).then(result => {
            return result.data.data;
        }).catch(e => {
            console.error(e);
            return [];
        });
    }

    function setupFilters() {
        const filter = {
            startDate: startDate.getTime(),
            endDate: endDate.getTime(),
            incidences: selIncidences.length > 0 ? selIncidences.map(item => { if (item != null) return item.id }) : allIncidences.map(item => { return item ? item.id : null }),
            status: selStatus.length > 0 ? selStatus.map(item => { return item.value }) : listStatus.map(item => { return item.value }),
            sectors: selSector.length > 0 ? selSector.map(item => { if (item != null) return item.id }) : allSectores.map(item => { return item ? item.id : null })
        }
        if (selStatus.length < 1) {
            setSelStatus(listStatus);
        }
        if (selIncidences.length < 1) {
            setSelIncidences(allIncidences);
        }
        if (selSector.length < 1) {
            setSelSector(allSectores);
        }
        return filter;
    }

    function executeFilters() {
        const filter = setupFilters();
        requestZoneStatistics(filter).then(result => {
            props.setStatsData(result);
        });
    }

    function executeExcel() {
        const filter = setupFilters();
        requestExportExcel(filter);
    }

    function renderSelectOptions() {
        let items = [];
        items.push(<MenuItem key={'inc-0'} value={sinAsignar}>
            <Checkbox checked={selIncidences.indexOf(sinAsignar) > -1} />
            <ListItemText primary={sinAsignar.title} />
        </MenuItem>);
        dataCatIncidences.forEach(item => {
            items.push(<ListSubheader value="" style={{ zIndex: 3, background: 'white' }} key={'ls' + item.id}>{item.title}</ListSubheader>);
            items.push(item.incidences.map(incidence => (
                <MenuItem key={'inc' + incidence.id} value={incidence}>
                    <Checkbox checked={selIncidences.indexOf(incidence) > -1} />
                    <ListItemText primary={incidence.title} />
                </MenuItem>
            )));
        });
        return items;
    }

    const renderSectorOptions = () => {
        const sinAsignarItem =
            <MenuItem key={'sec-0'} value={sinAsignar}>
                <Checkbox checked={selSector.indexOf(sinAsignar) > -1} />
                <ListItemText primary={sinAsignar.title} />
            </MenuItem>;

        const sectorItems = sectores.map(sector =>
            <MenuItem key={'sector' + sector.id} value={sector}>
                <Checkbox checked={selSector.indexOf(sector) > -1} />
                <ListItemText primary={sector.name} />
            </MenuItem>
        )

        //return [sinAsignarItem, ...sectorItems];
        return sectorItems;
    }

    React.useEffect(() => {
        requestCatIncidences().then(result => {
            setDataCatIncidences(result);
            let tSelectedInc = [];
            result.forEach(item => {
                item.incidences.forEach(inc => {
                    tSelectedInc.push(inc);
                })
            });
            tSelectedInc.push(sinAsignar);
            allIncidences = tSelectedInc;
            setTimeout(() => { executeFilters(tSelectedInc) }, 100);
        });

        requestSectores().then(sectores => {
            setSectores(sectores);
            allSectores = [...sectores, sinAsignar];
        });
    }, []);


    return (
        <React.Fragment>
            <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <Paper className={classes.paperContainer}>
                    <Grid item xs={12} md={9} style={{ paddingBottom: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel className={classes.formControl} style={{ padding: 0 }}
                                    control={
                                        <DateTimePicker inputProps={{ style: { padding: '6px 12px' } }} className={classes.formControl} variant="inline" inputVariant="outlined" value={startDate} onChange={onDateStartChange} format="dd/MM/yyyy HH:mm" />
                                    }
                                    label={<span className={classes.dateInputLabel}>Fecha de inicio</span>}
                                    labelPlacement="top" />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControlLabel className={classes.formControl}
                                    control={
                                        <DateTimePicker inputProps={{ style: { padding: '6px 12px' } }} className={classes.formControl} variant="inline" inputVariant="outlined" value={endDate} onChange={onDateEndChange} format="dd/MM/yyyy HH:mm" />
                                    }
                                    label={<span className={classes.dateInputLabel}>Fecha de fin</span>}
                                    labelPlacement="top" />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} md={3}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="lblStatus">Estado</InputLabel>
                                <Select
                                    labelId="lblStatus"
                                    id="selStatus"
                                    multiple
                                    value={selStatus}
                                    onChange={handleChangeSelStatus}
                                    input={<Input />}
                                    renderValue={selected => {
                                        let text = "";
                                        selected.forEach(item => {
                                            text = text + item.text + ', '
                                        })
                                        return text;
                                    }}
                                    MenuProps={MenuProps}>
                                    {listStatus.map(status => (
                                        <MenuItem key={status.value} value={status}>
                                            <Checkbox checked={selStatus.indexOf(status) > -1} />
                                            <ListItemText primary={status.text} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="lblSector">Sector</InputLabel>
                                <Select
                                    labelId="lblSector"
                                    id="selSector"
                                    multiple
                                    value={selSector}
                                    onChange={handleChangeSelSector}
                                    input={<Input />}
                                    renderValue={selected => selected.map(item => item ? (item.name || item.title) : null)
                                        .filter(text => text)
                                        .join(', ')}
                                    MenuProps={MenuProps}>
                                    {renderSectorOptions()}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={5} md={1}>
                        <Button onClick={executeFilters} style={{ width: '100%', fontSize: 12 }} variant="contained" color="primary">
                            Filtrar
                        </Button>
                    </Grid>
                    <Grid mb={4} item xs={5} md={2} xl={1}>
                        <Button onClick={executeExcel} style={{ width: '100%', fontSize: 12 }} variant="contained">
                            Exportar Excel
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
        </React.Fragment>
    );
});

export default Filtros;