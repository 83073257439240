import { Snackbar, SnackbarContent } from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import clsx from 'clsx';
import { useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './assets/App.scss';
import Routes from './components/route/Routes';
import constants from './constants';
import { AuthContext } from "./context/auth";
import { SideBarContext } from "./context/sidebar";
import { SnackBarContext } from "./context/snackBar";

const getAuthLocalStorage = () => {
  return JSON.parse(localStorage.getItem("auth"));
}

const checkSmallScreen = () => {
  const size = Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
  return size < 720;
}

const snackbarStyle = makeStyles(theme => ({
  snackbar: {
    opacity: 0.95
  },
  snackbarContent: {
    fontSize: 15
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const App = () => {
  const [auth, setAuth] = useState(getAuthLocalStorage);
  const [sideBar, setSideBar] = useState(!checkSmallScreen());
  const [snackBar, setSnackBar] = useState({ open: false, variant: 'default', text: '' });

  const snackStyles = snackbarStyle();

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth ? auth.token : ''
  }

  function requestLogout() {
    return axios.post(constants.apiBase + "auth/logout", null, {
      headers
    }).then(result => {
      localStorage.removeItem("auth");
      localStorage.removeItem("fcm");
      setAuth(null);
    }).catch(e => {
      console.error(e);
      return null;
    });
  }

  const saveAuthLocalStorage = (data) => {
    if (data == null) {
      requestLogout();
    }
    localStorage.setItem("auth", JSON.stringify(data));
    setAuth(data);
  }

  const changeSideBar = (value) => {
    setSideBar(value);
  }

  const handleOpenSnack = (value) => {
    setSnackBar(value);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar({ open: false, variant: 'success', text: '' });
  };

  return (
    <div id="app">
      <AuthContext.Provider value={{ auth, setAuth: saveAuthLocalStorage }}>
        <SideBarContext.Provider value={{ sideBar, setSideBar: changeSideBar }}>
          <SnackBarContext.Provider value={{ snackBar, openSnackBar: handleOpenSnack, closeSnackBar: handleCloseSnack }}>
            <Router>
              <Routes />
            </Router>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              className={snackStyles.snackbar}
              open={snackBar.open}
              autoHideDuration={4000}
              onClose={handleCloseSnack}>
              <SnackbarContent
                className={clsx(snackStyles[snackBar.variant], snackStyles.snackbarContent)}
                aria-describedby="client-snackbar"
                message={
                  <span id="client-snackbar">
                    {snackBar.text}
                  </span>
                }
                action={[
                  <IconButton key="close" aria-label="close" color="inherit" onClick={handleCloseSnack}>
                    <CloseIcon className={snackStyles.icon} />
                  </IconButton>,
                ]}
              />
            </Snackbar>
          </SnackBarContext.Provider>
        </SideBarContext.Provider>
      </AuthContext.Provider>
    </div>
  )
};

export default App;